/**
 * This is a customizable CSS file where classes can be
 * added that appear in the celanio backend web-editor.
 *
 * If necessary override this file in customer space.
 */

@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  src: local("Source Sans Pro"), local("SourceSansPro-Regular"), url("https://fonts.gstatic.com/s/sourcesanspro/v9/ODelI1aHBYDBqgeIAH2zlNOAHFN6BivSraYkjhveRHY.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  src: local("Source Sans Pro"), local("SourceSansPro-Regular"), url("https://fonts.gstatic.com/s/sourcesanspro/v9/ODelI1aHBYDBqgeIAH2zlC2Q8seG17bfDXYR_jUsrzg.woff2") format("woff2");
  unicode-range: U+100-24F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  src: local("Source Sans Pro"), local("SourceSansPro-Regular"), url("https://fonts.gstatic.com/s/sourcesanspro/v9/ODelI1aHBYDBqgeIAH2zlNV_2ngZ8dMf8fLgjYEouxg.woff2") format("woff2");
  unicode-range: U+0-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  src: local("Source Sans Pro Bold"), local("SourceSansPro-Bold"), url("https://fonts.gstatic.com/s/sourcesanspro/v9/toadOcfmlt9b38dHJxOBGDovqjS_dXPZszO_XltPdNg.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  src: local("Source Sans Pro Bold"), local("SourceSansPro-Bold"), url("https://fonts.gstatic.com/s/sourcesanspro/v9/toadOcfmlt9b38dHJxOBGFxe-GPfKKFmiXaJ_Q0GFr8.woff2") format("woff2");
  unicode-range: U+100-24F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  src: local("Source Sans Pro Bold"), local("SourceSansPro-Bold"), url("https://fonts.gstatic.com/s/sourcesanspro/v9/toadOcfmlt9b38dHJxOBGEo0As1BFRXtCDhS66znb_k.woff2") format("woff2");
  unicode-range: U+0-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 400;
  src: local("Source Sans Pro Italic"), local("SourceSansPro-It"), url("https://fonts.gstatic.com/s/sourcesanspro/v9/M2Jd71oPJhLKp0zdtTvoM7YHq4FgHI02B8rPccK0FJQ.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 400;
  src: local("Source Sans Pro Italic"), local("SourceSansPro-It"), url("https://fonts.gstatic.com/s/sourcesanspro/v9/M2Jd71oPJhLKp0zdtTvoM40tgx99jmYGv_xzYuwd1rU.woff2") format("woff2");
  unicode-range: U+100-24F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 400;
  src: local("Source Sans Pro Italic"), local("SourceSansPro-It"), url("https://fonts.gstatic.com/s/sourcesanspro/v9/M2Jd71oPJhLKp0zdtTvoMxgy2Fsj5sj3EzlXpqVXRKo.woff2") format("woff2");
  unicode-range: U+0-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 700;
  src: local("Source Sans Pro Bold Italic"), local("SourceSansPro-BoldIt"), url("https://fonts.gstatic.com/s/sourcesanspro/v9/fpTVHK8qsXbIeTHTrnQH6J-EY3txTwhUdSs_eHhDaJM.woff2") format("woff2");
  unicode-range: U+102-103, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 700;
  src: local("Source Sans Pro Bold Italic"), local("SourceSansPro-BoldIt"), url("https://fonts.gstatic.com/s/sourcesanspro/v9/fpTVHK8qsXbIeTHTrnQH6Kr3NmSntz3GE6rIpxOPZGY.woff2") format("woff2");
  unicode-range: U+100-24F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 700;
  src: local("Source Sans Pro Bold Italic"), local("SourceSansPro-BoldIt"), url("https://fonts.gstatic.com/s/sourcesanspro/v9/fpTVHK8qsXbIeTHTrnQH6Nnl6YROR5rHLkdLoHwoOWA.woff2") format("woff2");
  unicode-range: U+0-FF, U+131, U+152-153, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}