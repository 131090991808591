/*Footer*/
footer.cel-footer-wrapper{
  .cel-footer-inner-wrapper {
    border-top: 1px solid #cecece;
    padding: 20px 0;
  }
  //Footer link
  .cel-footermenu{
    //display: inline-block;
    float: right;
    a{
      font-weight: normal;
      font-size: 14px;
      text-decoration: none;
      color: #888;
      line-height: 20px;
      margin-top: 5px;
      margin-left: 15px;
      margin-right: 0px;
    }
  }
}