// Core variables and mixins
$screen-xxs-min: 320px;
$screen-xxs-max: ($screen-sm-min - 1);
$celanio-blue: #158fd0;

//celanio variable
$primary-color: #158fd0;
$secondary-color: #575756;
$additional-color: #575756;

$gray-1-color:#878787;
$gray-2-color:#b3b2b2;
$gray-3-color:#dadada;

//celanio variable
$primary-color: #c9006a;
//$secondary-color: #FFF;

//need to define variable
$additional-color: #ddee63;
$additional-color-hover: #CDE617;

$gray-1-color:#878787;
$gray-2-color:#9d9d9c;
$gray-3-color:#F2F7FB;

$main-font: "Source Sans Pro",Arial,Helvetica,Garuda,sans-serif;
$header-font: "Source Sans Pro",Arial,Helvetica,Garuda,sans-serif;
$headersmall-font: "Source Sans Pro",Arial,Helvetica,Garuda,sans-serif;
$navbutton-font: "Source Sans Pro",Arial,Helvetica,Garuda,sans-serif;

//header variable
$header-background-color:$primary-color;
$header-p-color:#FFF;
$header-a-color:#FFF;
$header-h-color:#FFF;

//footer variable
$footer-background-color:$primary-color;
$footer-p-color:#FFF;
$footer-a-color:#FFF;
$footer-h-color:#FFF;