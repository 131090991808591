/**
 * This is a customizable CSS file where classes can be
 * added that appear in the celanio backend web-editor.
 *
 * If necessary override this file in customer space.
 */
// Core customer variables
@import "bootstrap/variables";
// Core variables and mixins
@import "../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins";
@import "../node_modules/font-awesome-sass/assets/stylesheets/font-awesome";
@import "customer/variables";
@import "float-label/float-label";
@import "customer/sso";
@import "customer/fonts";
@import "customer/variables";
@import "bootstrap/tree-view";

@import "module/010400";
@import "module/010600";
@import "module/010700";
@import "module/010800";
@import "module/010900";
@import "module/011200";
@import "module/011300";
@import "module/011400";
@import "module/011700";
@import "module/011800";
@import "module/011901";
@import "module/011903";
@import "module/011905";
@import "module/011910";
@import "module/011911";
@import "module/011912";
@import "module/011919";
@import "module/011922";
@import "module/012100";
@import "module/012200";
@import "module/013100";
@import "module/014000";
@import "module/070100";
$celanio-blue: #158fd0;

/*Default*/
.cel-active > a {
    color: #23527c;
    text-decoration: underline;
}

#topnavaccordion{
    span.caret{
        display: none;
    }
    a.head{
        padding-top: 23px;
        padding-left: 12px;
        padding-right: 12px;
        padding-bottom: 5px;
        position: relative;
        display: block;
        color: #888;
        line-height: 20px;
        &:hover, &:active,&:focus{
            color:#5F5F5F;
            text-decoration: none;
        }
        a.head,
        a.sub{
            white-space: nowrap;
        }
    }
}

.cel-menuhide{
    display: none;
}

.cel-space{
    margin-top: 10px;
}
.cel-bottom-separator{
    border-bottom: 1px solid #e5e5e5;
}

.cel-smiley {
    height:  22px;
    width:   22px;
    display: inline-block;
}

.cel-smiley-1 {
    background: url("../../gfx/22x22/emotes/face-smile.png") no-repeat;
}

.cel-smiley-2 {
    background: url("../../gfx/22x22/emotes/face-plain.png") no-repeat;
}

.cel-smiley-3 {
    background: url("../../gfx/22x22/emotes/face-sad.png") no-repeat;
}

.panel-body .col-sm-3 h3 {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    margin-bottom: 5px;
    padding-bottom: 10px;
}

.control-label em,
.cel-mandatory-field,
.necessary_field{
    color: red;
}
.cel-button{
    margin-right: 10px;
}
.cel-button-wrapper{
    .btn{
        margin-right: 10px;
    }
}
/* Background*/
html,body{
    height: 100%; /* needed for container min-height */
}

.cel-page{
    position: relative;
}

.cel-backgroundcolor{
    background-color: #f5f5f3;
}

.cel-backgroundimage{
    background-position: center top;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

/*Main Body*/
.container,.container-fluid{
    background-color:white;
    padding:0;
    overflow-x: hidden;
}
/*Header*/
.cel-page .cel-logo{
    max-width: 170px;
    display: inline-flex;
}
.cel-logo.cel-top{
    img{
        max-width: 290px;
        padding: 20px;
        display: inline-block;
    }
    .search{
        padding: 20px;
        float: right;
        display: inline-block;
        width: 300px;
        .btn-primary {
            height: 36px;
            min-width: 36px;
            padding: 6px 7px;
        }
    }
}


nav.navbar{
    border: none;
    margin-bottom: 0;
    position: relative;
    border-radius: 0;
    .navbar-collapse {
        padding-top: 30px;
        padding-left: 240px;
    }
    .search{
        float: right;
        form{
          padding: 7px 0;
        }
    }
}

.cel-banner{
    img{
        height: 100px;
        width: 100%;
    }
}
.cel-avatar-container {
    background-color: #222;
    position: relative;
    min-height: 44px;
    .cel-avatar {
        position: absolute;
        right: 0;
        color: #FFF;
        padding: 10px;
        img {
            margin-left: 10px;
            display: inline-flex;
            height: 20px;
            width: 20px;
            border-radius: 50%;
        }
        form{
            display: inline-flex;
            margin: 0;
        }
        button{
            margin-left: 20px;
            padding: 4px;
            border-radius:50px ;
        }
    }
}

#cel-webshop-switch{
    .row{
        display: flex;
    }
    .cel-btn-group{
        position: absolute;
        bottom: 0;
        left: 28px;
        right: 28px;
    }
    form{
        margin-bottom: 40px;
    }
}

#cel-webshop-switch-register.cel-register,
#cel-webshop-switch-login.cel-login{
    border-right:none;
    .cel-btn-group{
        position: inherit;
    }
    form{
        margin-bottom: 0px;
    }
}
#cel-webshop-switch.cel-login{
    border-right:none;
    .cel-btn-group{
        position: inherit;
    }
    form{
        margin-bottom: 0px;
    }
}
/*Content*/
.container-fluid .cel-content,
.container .cel-content{
    padding: 30px;
}

ul.cel-sidemenu{
    padding-top: 20px; padding-bottom: 20px;
    > li{
        border-bottom: 1px solid #737373;
    }
    > li:last-child{
        border-bottom: none;
    }
    ul.dropdown-menu{
        position: relative;
        float: none;
        border: none;
        border-radius: 0;
        box-shadow: none;
    }
}

#cel-webshop-switch-login {
    border-right: solid 2px #a8a8af;
}

#cel-webshop-switch-login,
#cel-webshop-switch-register {
    padding: 0 28px;
}

#cel-webshop-switch {
    max-width: 800px;
}

.modal-dialog .ajax-loader {
    visibility: hidden;
    background-color: #9999998f;
    margin: -19px;
    position: absolute;
    z-index: +100 !important;
    width: 100%;
    height:100%;
    text-align: center;
    font-size: 30px;
}

.modal-dialog .ajax-loader{
    margin: 0;
}

.ajax-loader i.fa {
    position: relative;
    top: 25%;
    margin: auto;
}

.cel-alert{
    display: none;
}
@import "customer/vnb";
@import "customer/footer";
@import "customer/responsive";