/* page container*/
.cel-page{
  background-color: #ffffff;
  padding-right: 45px;
  padding-left: 45px;
  padding-top: 25px;
}
body{
  font-size: 16px;
  font-family: $main-font;
  color: #999;
  line-height: 1.4;
}
/* navigation */

.navbar-default .navbar-collapse, .navbar-default .navbar-form{
  background-color:#FFF;
  a{
    font-weight: normal;
  }
}

/* main object layout*/

//heading
h1, .h1 {
  font-size: 22px;
  margin-bottom: 0.8em;
}

h2, .h2 {
  font-size: 20px;
}

h3, .h3, h4, .h4, h5, .h5, h6, .h6{
  font-size: 18px;
}

h1,h2,h3,h4,h5,h6{
  color: #777;
  font-weight: bold;
  line-height: 1.2em;
}

//Link
a{
  color: #c9006a;
  font-weight: bold;
}
a:hover, a:focus {
  color: #c9006a;
  text-decoration: underline;
}
a.page-link{
  color: #c9006a !important;
}
//button
.btn-primary{
  background-color: $primary-color;
  border-color: $primary-color;
}

.btn-primary[disabled]:hover,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:visited,
.btn-primary:hover {
  color: #fff;
  background-color: $primary-color;
  border-color: $primary-color;
}